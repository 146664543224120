import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/app/index.module.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/assets/images/demo-img.jpeg");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/assets/images/hero-img.png");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/assets/svgs/copyright.svg");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/assets/svgs/facebook.svg");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/assets/svgs/instagram.svg");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/assets/svgs/logo.svg");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/assets/svgs/twitter.svg");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/components/common/button/index.module.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/components/common/header/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/components/contentSection/index.module.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/components/featureSection/index.module.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/components/demoSection/index.module.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/components/newsletterSection/index.module.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/ccx-website-v3/src/components/common/footer/index.module.css")