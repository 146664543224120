import React, { ButtonHTMLAttributes, PropsWithChildren } from "react";
import cls from "./index.module.css";

interface ButtonProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
	theme: "primary" | "secondary";
}

export default function Button({ children, theme, ...props }: ButtonProps) {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<button className={theme === "primary" ? cls.BTN_PRIMARY : cls.BTN_SECONDARY} {...props}>
			{children}
		</button>
	);
}
