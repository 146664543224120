"use client";
import React, { useCallback, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import logo from "../../../assets/svgs/logo.svg";
import SideNav from "../SideNav";
import Button from "../button";
import cls from "./index.module.css";

export default function Header() {
	const [isNavOpen, setIsNavOpen] = useState(false);

	const handleClick = useCallback(() => {
		setIsNavOpen((prevState) => !prevState);
	}, []);

	return (
		<>
			<div className={cls.HEADER_CONTAINER}>
				<div className={cls.HEADER_CONTENT}>
					<Link href={"/"} className={cls.LOGO}>
						<Image src={logo} alt="phaeton-logo" height={77} width={250} />
					</Link>
					<div className={cls.NAV_ITEMS}>
						<Link className={cls.NAVLINK} href={"/how-it-works"}>
							How It Works
						</Link>
						<Link className={cls.NAVLINK} href={"/team"}>
							Team
						</Link>
						<Link className={cls.NAVLINK} href={"/sell-your-credit"}>
							Sell your Credits
						</Link>
						<Link className={cls.NAVLINK} href={"/about-us"}>
							About Us
						</Link>
						<Link className={cls.NAVLINK} href={"/b1g1"}>
							B1G1
						</Link>
						<Link href={"/contact-us"}>
							<Button theme="primary">Register Interest</Button>
						</Link>
					</div>
				</div>
			</div>
			<div
				className={cls.MSITE_HEADER_CONTAINER}
				// $changeBG={triggerBGChange}
			>
				<div className={cls.LOGO_CONTAINER}>
					<Link href={"/"} aria-label="Home">
						<Image src={logo} alt="phaeton-logo" height={46} width={149} />
					</Link>
				</div>
				<div className={cls.HAMBURGER_MENU}>
					<input
						className={cls.CHECKBOX}
						type="checkbox"
						id="checkbox"
						onChange={handleClick}
						value={JSON.stringify(isNavOpen)}
						checked={isNavOpen}
					/>
					<label htmlFor="checkbox" className={cls.TOGGLE}>
						<div className={isNavOpen ? cls.BARS_1 : cls.BARS} id="bar1"></div>
						<div className={isNavOpen ? cls.BARS_2 : cls.BARS} id="bar2"></div>
						<div className={isNavOpen ? cls.BARS_3 : cls.BARS} id="bar3"></div>
					</label>
				</div>
				<SideNav show={isNavOpen} onBackdropClick={handleClick}>
					<div className={cls.CONTENT}>
						<Link className={cls.NAVLINK} href={"/how-it-works"}>
							How It Works
						</Link>
						<Link className={cls.NAVLINK} href={"/team"}>
							Team
						</Link>
						<Link className={cls.NAVLINK} href={"/sell-your-credit"}>
							Sell your Credits
						</Link>
						<Link className={cls.NAVLINK} href={"/about-us"}>
							About Us
						</Link>
						<Link className={cls.NAVLINK} href={"/b1g1"}>
							B1G1
						</Link>
						<Link href={"/contact-us"}>
							<Button theme="primary">Register Interest</Button>
						</Link>
					</div>
				</SideNav>
			</div>
		</>
	);
}
