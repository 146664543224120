"use client";
import React, { ReactNode } from "react";
import cls from "./index.module.css";

type BottomSheetProps = {
	children: ReactNode;
	show: boolean;
	onBackdropClick: () => void;
	onClose?: () => void;
	showCloseButton?: boolean;
};

export default function SideNav({
	children,
	show,
	onBackdropClick,
	onClose,
	showCloseButton = false,
}: BottomSheetProps) {
	return (
		<div className={show ? cls.WRAPPER_ACTIVE : cls.WRAPPER}>
			<div className={show ? cls.BACKDROP_ACTIVE : cls.BACKDROP} onClick={onBackdropClick} />
			<div className={show ? cls.FOREGROUND_ACTIVE : cls.FOREGROUND}>
				{showCloseButton && (
					<div className={cls.CLOSE_BTN_CONTAINER}>
						<div className={cls.CLOSE_BTN} onClick={onClose}>
							x
						</div>
					</div>
				)}
				{children}
			</div>
		</div>
	);
}
